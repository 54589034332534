import { Chip } from '@mui/material';
import { Cloud as CloudIcon } from '@mui/icons-material';
import React from 'react';
import useGetServerStateColor from 'hooks/useGetServerStateColor';
import useGetServerStateName from 'hooks/useGetServerStateName';

const StateChip = () => {
  const color = useGetServerStateColor();
  const label = useGetServerStateName();

  return (
    <Chip color={color} label={label} icon={<CloudIcon />} size='medium' />
  );
};

export default StateChip;
