import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Grid } from '@mui/material';

const ViewEmailMessage = ({ email }) => {
  return (
    <Grid item>
      <Alert severity='info'>We have sent a login link to {email}. Please check your spam folder.</Alert>
    </Grid>
  );
};

ViewEmailMessage.propTypes = {
  email: PropTypes.string.isRequired
};

export default ViewEmailMessage;
