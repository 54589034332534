import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  microsoft: {
    AzureID: '',
    AzureKey: '',
    AzureDirectoryID: ''
  }
};

export const identitySlice = createSlice({
  name: 'identity',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setIdentity: (state, action) => {
      return action.payload;
    },
    setIdentityMicrosoft: (state, action) => {
      state.microsoft = { ...action.payload };
    }
  }
});

export const { setIdentity, setIdentityMicrosoft } = identitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getIdentity = (state) => state.identity;
export const getMicrosoftIdentity = (state) => state.identity.microsoft;
export const getAzureID = (state) => state.identity.microsoft.AzureID;
export const getAzureKey = (state) => state.identity.microsoft.AzureKey;
export const getAzureDirectoryID = (state) => state.identity.microsoft.AzureDirectoryID;

export default identitySlice.reducer;
