import { createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState = {
  email: '',
  domain: '',
  fmData: '',
  fmAdmin: ''
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFmAdmin: (state, action) => {
      state.fmAdmin = action.payload;
    },
    setFmData: (state, action) => {
      state.fmData = action.payload
    },
    setUser: (state, action) => {
      return action.payload;
    }
  }
});

export const { setFmAdmin, setFmData, setUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getDomain = (state) => state.user.domain;
export const getEmail = (state) => state.user.email;
export const getFmAdminToken = (state) => state.user.fmAdmin;
export const getFmDataToken = (state) => state.user.fmData;

export default userSlice.reducer;
