import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';

const Wrapper = ({ children }) => {
  return (
    <Grid container direction='column' sx={{ height: '100vh', width: '50vh' }} alignItems='center' justifyContent='center'>
      <Grid item container direction='column' spacing={2}>
        <Grid item alignSelf='center'>
          <Avatar>
            <Lock />
          </Avatar>
        </Grid>
        <Grid item alignSelf='center'>
          <Typography variant='h4'>Sign In</Typography>
        </Grid>
        <Grid item container direction='column' spacing={2} sx={{ height: '163.5px' }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Wrapper;
