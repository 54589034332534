import axios, { isAxiosError } from 'axios';
import { getServerInstanceId, setServer } from 'state/server/serverSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const useGetServerInfo = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const instanceId = useSelector(getServerInstanceId)

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/status`, {
        instanceId
      });

      if (response.data.server) {
        dispatch(setServer(response.data.server));
      }
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 4000 });
        return;
      }

      enqueueSnackbar(error.response.data.error, { variant: 'error', autoHideDuration: 4000 });
    }
  }, [dispatch, enqueueSnackbar, instanceId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 25000);

    return () => clearInterval(interval);
  }, [fetchData]);
};

export default useGetServerInfo;
