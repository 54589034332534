import { getServerName } from 'state/server/serverSlice';
import React from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const Title = (props) => {
  const serverName = useSelector(getServerName);
  return <Typography variant='h6'>Server: {serverName}</Typography>;
};

Title.propTypes = {};

export default Title;
