import Auth from 'containers/Auth';
import Authenticate from './containers/Authenticate';
import Dashboard from 'containers/Dashboard';
import Login from './containers/Login';
import NotFound from './containers/NotFound';
import { Route, Routes } from 'react-router-dom';

const Pages = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/auth/:domain/:code' element={<Authenticate />} />
      <Route element={<Auth />}>
        <Route path='/dashboard' element={<Dashboard />} />
      </Route>
      <Route path='/public' element={<Dashboard />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default Pages;
