import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';


const KeyForm = ({ formik }) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <TextField
          fullWidth
          id='AzureID'
          name='AzureID'
          label='Azure ID'
          disabled
          value={formik.values.AzureID}
          onChange={formik.handleChange}
          error={formik.touched.AzureID && Boolean(formik.errors.AzureID)}
          helperText={formik.touched.AzureID && formik.errors.AzureID}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id='AzureDirectoryID'
          name='AzureDirectoryID'
          label='Azure Directory ID'
          disabled
          value={formik.values.AzureDirectoryID}
          onChange={formik.handleChange}
          error={formik.touched.AzureDirectoryID && Boolean(formik.errors.AzureDirectoryID)}
          helperText={formik.touched.AzureDirectoryID && formik.errors.AzureDirectoryID}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id='AzureKey'
          name='AzureKey'
          label='Azure Key'
          value={formik.values.AzureKey}
          onChange={formik.handleChange}
          error={formik.touched.AzureKey && Boolean(formik.errors.AzureKey)}
          helperText={formik.touched.AzureKey && formik.errors.AzureKey}
        />
      </Grid>
      <Grid item>
        <LoadingButton color='primary' variant='contained' fullWidth loading={formik.isSubmitting} onClick={formik.handleSubmit}>
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

KeyForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default KeyForm;
