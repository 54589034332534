class Ec2States {
  PENDING = 'pending';
  RUNNING = 'running';
  SHUTTING_DOWN = 'shutting-down';
  TERMINATED = 'terminated';
  STOPPING = 'stopping';
  STOPPED = 'stopped';

  constructor() {
    Object.freeze(this);
  }
}

export default Ec2States;
