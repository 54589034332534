import React from 'react';
import { Typography } from '@mui/material';

const Description = (props) => {
  return (
    <>
      <Typography variant='body1'>This section allows you to restart your filemaker server machine.</Typography>
      <Typography variant='body1'>
        To restart the machine, please click the stop button and then click the start button once it becomes available.
      </Typography>
      <Typography variant='body1'>
        Please be aware that the server can take about 5-10 mins while starting and stopping.
      </Typography>
    </>
  );
};

export default Description;
