import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Grid, TextField } from '@mui/material';

const LoginForm = ({ formik }) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        formik.handleSubmit(event);
      }
    },
    [formik]
  );

  return (
    <>
      <Grid item>
        <TextField
          fullWidth
          id='email'
          name='email'
          label='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onKeyDown={handleKeyDown}
        />
      </Grid>
      <Grid item>
        <LoadingButton color='primary' variant='contained' fullWidth loading={formik.isSubmitting} onClick={formik.handleSubmit}>
          Submit
        </LoadingButton>
      </Grid>
    </>
  );
};

LoginForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default LoginForm;
