import Routes from './Routes';
import { Container } from '@mui/material';

const App = () => {
  return (
    <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Routes />
    </Container>
  );
};

export default App;
