class LoginFormStatuses {
  LOGIN = 0;
  VIEW_EMAIL = 1;

  constructor() {
    Object.freeze(this);
  }
}

export default LoginFormStatuses;
