import identityReducer from './identity/identitySlice';
import serverReducer from './server/serverSlice';
import userReducer from './user/userSlice';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    identity: identityReducer,
    server: serverReducer,
    user: userReducer
  }
});
