import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  instanceId: '',
  name: '',
  state: '',
};

export const serverSlice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setServer: (state, action) => {
      return action.payload;
    }
  }
});

export const { setServer } = serverSlice.actions;

export const getServerInstanceId = (state) => state.server.instanceId;
export const getServerName = (state) => state.server.name;
export const getServerState = (state) => state.server.state;

export default serverSlice.reducer;
