import React from 'react';
import { Typography } from '@mui/material';

const Description = () => {
  return (
    <Typography variant='body1'>
      Please enter the new key provided by Keywords Studios. This key rotates every ~6 months.
    </Typography>
  );
};

export default Description;
