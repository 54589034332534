import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import axios, { isAxiosError } from 'axios';
import DashboardAccordions from 'enums/DashboardAccordions';
import Description from './Description';
import { getAzureID, getAzureDirectoryID, getAzureKey, setIdentityMicrosoft } from 'state/identity/identitySlice';
import { getDomain, getFmAdminToken, getFmDataToken, setFmAdmin, setFmData } from 'state/user/userSlice';
import KeyForm from './KeyForm';
import React, { useCallback, useMemo, useState } from 'react';
import Title from './Title';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import validationSchema from './validationSchema';

const ACCORDION = new DashboardAccordions();

const AzureAccordion = () => {
  const AzureID = useSelector(getAzureID);
  const AzureDirectoryID = useSelector(getAzureDirectoryID);
  const AzureKey = useSelector(getAzureKey);
  const dispatch = useDispatch();
  const domain = useSelector(getDomain);
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(ACCORDION.CLOSED);
  const fmAdminToken = useSelector(getFmAdminToken);
  const fmDataToken = useSelector(getFmDataToken);

  const initialValues = useMemo(
    () => ({
      AzureID,
      AzureDirectoryID,
      AzureKey
    }),
    [AzureID, AzureDirectoryID, AzureKey]
  );

  const handleChange = useCallback(
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : ACCORDION.CLOSED);
    },
    []
  );

  const handleSubmit = useCallback(
    async (values, { setFieldError }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/updateMicrosoftIdentity`, {
          ...values,
          domain,
          fmAdminToken,
          fmDataToken
        });

        enqueueSnackbar('Updated Azure Key.', { variant: 'success', autoHideDuration: 4000 });
        if (response.data.identity?.microsoft) {
          dispatch(setIdentityMicrosoft(response.data.identity?.microsoft));
        }

        if (response.data.user?.fmAdmin) {
          dispatch(setFmAdmin(response.data.user?.fmAdmin))
        }

        if (response.data.user?.fmData) {
          dispatch(setFmData(response.data.user?.fmData))
        }
      } catch (error) {
        if (isAxiosError(error)) {
          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 4000 });
          return;
        }

        enqueueSnackbar(error.response.data.error, { variant: 'error', autoHideDuration: 4000 });
      }
    },
    [dispatch, domain, enqueueSnackbar, fmAdminToken, fmDataToken]
  );

  const formik = useFormik({ initialValues, validationSchema, onSubmit: handleSubmit });

  return (
    <Accordion expanded={expanded === ACCORDION.AZURE} onChange={handleChange(ACCORDION.AZURE)}>
      <AccordionSummary>
        <Title />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Description />
          </Grid>
          <Grid item>
            <KeyForm formik={formik} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AzureAccordion;
