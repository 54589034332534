import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import axios, { isAxiosError } from 'axios';
import DashboardAccordions from 'enums/DashboardAccordions';
import Description from './Description';
import { getDomain, getFmDataToken } from 'state/user/userSlice';
import { getServerInstanceId, setServer } from 'state/server/serverSlice';
import React, { useCallback, useMemo, useState } from 'react';
import StartForm from './StartForm';
import StateChip from './StateChip';
import StopForm from './StopForm';
import Title from './Title';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import useGetServerInfo from 'hooks/useGetServerInfo';
import { useSnackbar } from 'notistack';
import validationSchema from './validationSchema';

const ACCORDION = new DashboardAccordions();

const ServerAccordion = () => {
  useGetServerInfo();
  const [expanded, setExpanded] = useState(ACCORDION.CLOSED);
  const dispatch = useDispatch();
  const domain = useSelector(getDomain);
  const { enqueueSnackbar } = useSnackbar();
  const fmDataToken = useSelector(getFmDataToken);
  const instanceId = useSelector(getServerInstanceId);

  const handleChange = useCallback(
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : ACCORDION.CLOSED);
    },
    []
  );

  const initialValues = useMemo(() => ({ instanceId }), [instanceId]);

  const handleSubmitStart = useCallback(
    async (values) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/start`, {
          ...values,
          domain,
          fmDataToken
        });

        enqueueSnackbar('Server Starting.', { variant: 'success', autoHideDuration: 4000 });
        if (response.data.server) {
          dispatch(setServer(response.data.server));
        }
      } catch (error) {
        if (isAxiosError(error)) {
          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 4000 });
          return;
        }

        enqueueSnackbar(error.response.data.error, { variant: 'error', autoHideDuration: 4000 });
      }
    },
    [dispatch, domain, enqueueSnackbar, fmDataToken]
  );

  const handleSubmitStop = useCallback(
    async (values) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/stop`, {
          ...values,
          domain,
          fmDataToken
        });

        enqueueSnackbar('Server Stopping.', { variant: 'success', autoHideDuration: 4000 });
        if (response.data.server) {
          dispatch(setServer(response.data.server));
        }
      } catch (error) {
        if (isAxiosError(error)) {
          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 4000 });
          return;
        }

        enqueueSnackbar(error.response.data.error, { variant: 'error', autoHideDuration: 4000 });
      }
    },
    [dispatch, domain, enqueueSnackbar, fmDataToken]
  );

  const formikStart = useFormik({ initialValues, validationSchema, onSubmit: handleSubmitStart });
  const formikStop = useFormik({ initialValues, validationSchema, onSubmit: handleSubmitStop });

  return (
    <Accordion expanded={expanded === ACCORDION.SERVER} onChange={handleChange(ACCORDION.SERVER)}>
      <AccordionSummary>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Title />
          </Grid>
          <Grid item alignSelf='end'>
            <StateChip />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Description />
          </Grid>
          <Grid container item justifyContent='space-between'>
            <StartForm formik={formikStart} />
            <StopForm formik={formikStop} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ServerAccordion;
