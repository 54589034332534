import Ec2States from 'enums/Ec2States';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getServerState } from 'state/server/serverSlice';

const EC2STATES = new Ec2States();

const useGetServerStateName = (props) => {
  const serverState = useSelector(getServerState);

  const name = useMemo(() => {
    const states = {
      [EC2STATES.PENDING]: 'Pending',
      [EC2STATES.RUNNING]: 'Running',
      [EC2STATES.SHUTTING_DOWN]: 'Shutting Down',
      [EC2STATES.STOPPED]: 'Stopped',
      [EC2STATES.STOPPING]: 'Stopping',
      [EC2STATES.TERMINATED]: 'Terminated'
    };

    return states[serverState];
  }, [serverState]);

  return name;
};

export default useGetServerStateName;
