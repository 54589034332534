import axios, { isAxiosError } from 'axios';
import LoginForm from './LoginForm';
import LoginFormStatusesEnum from 'enums/LoginFormStatuses';
import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import validationSchema from './validationSchema';
import ViewEmailMessage from './ViewEmailMessage';
import Wrapper from './Wrapper';

const initialValues = {
  email: ''
};

const LoginFormStatuses = new LoginFormStatusesEnum();

const Login = () => {
  const [formStatus, setFormStatus] = useState(LoginFormStatuses.LOGIN);
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = useCallback(
    async (values, { setFieldError }) => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { ...values });
        setFormStatus(LoginFormStatuses.VIEW_EMAIL);
        enqueueSnackbar('Check email for login link.', { variant: 'success', autoHideDuration: 4000 });
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response.status === 400) {
            setFieldError('email', error.response.data.error);
            return;
          }

          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 4000 });
        }
      }
    },
    [enqueueSnackbar]
  );

  const formik = useFormik({ initialValues, validationSchema, onSubmit: handleSubmit });

  const render = {
    [LoginFormStatuses.LOGIN]: <LoginForm formik={formik} />,
    [LoginFormStatuses.VIEW_EMAIL]: <ViewEmailMessage email={formik.values.email} />
  };

  return <Wrapper>{render[formStatus]}</Wrapper> ?? null;
};

export default Login;
