class DashboardAccordions {
  CLOSED = 0;
  SERVER = 1;
  AZURE = 2;

  constructor() {
    Object.freeze(this);
  }
}

export default DashboardAccordions;
