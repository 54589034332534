import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { getEmail } from 'state/user/userSlice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Auth = () => {
  const email = useSelector(getEmail);
  const location = useLocation();

  if (isEmpty(email)) {
    return <Navigate to='/' state={{ from: location }} />;
  }

  return <Outlet />;
};

export default Auth;
