import Ec2States from 'enums/Ec2States';
import { LoadingButton } from '@mui/lab';
import { getServerName, getServerState } from 'state/server/serverSlice';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const EC2STATES = new Ec2States();

const StopForm = ({ formik }) => {
  const serverName = useSelector(getServerName);
  const serverState = useSelector(getServerState);

  const isDisabled = useMemo(() => serverState !== EC2STATES.RUNNING, [serverState]);

  return (
    <Grid item>
      <LoadingButton
        color='error'
        variant='contained'
        fullWidth
        loading={formik.isSubmitting}
        onClick={formik.handleSubmit}
        disabled={isDisabled}
      >
        Stop {serverName}
      </LoadingButton>
    </Grid>
  );
};

StopForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default StopForm;
