import AzureAccordion from './AzureAccordion';
import React from 'react';
import ServerAccordion from './ServerAccordion';
import { Grid, Paper, Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant='h3'>FileMaker Server Toolbox</Typography>
          <Typography variant='h5'>Use this to restart the server and set Azure Key</Typography>
        </Paper>
      </Grid>
      <Grid item>
        <AzureAccordion />
        <ServerAccordion />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
