import axios, { isAxiosError } from 'axios';
import { setIdentity } from 'state/identity/identitySlice';
import { setServer } from 'state/server/serverSlice';
import { setUser } from 'state/user/userSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const Authenticate = () => {
  const { code, domain } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    let timeout;
    const authenticateHash = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/authenticate`, { code, domain });
        dispatch(setUser(response.data.user));
        dispatch(setIdentity(response.data.identity));
        dispatch(setServer(response.data.server));
        navigate('/dashboard');
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response.status === 400) {
            enqueueSnackbar(error.response.data.error, { variant: 'error', autoHideDuration: 4000 });
            timeout = setTimeout(() => {
              navigate('/');
            }, 4000);
            return;
          }

          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 4000 });
        }
      }
    };

    authenticateHash();

    return () => {
      clearTimeout(timeout);
    }
  }, [code, domain, dispatch, enqueueSnackbar, navigate]);

  return null;
};

export default Authenticate;
