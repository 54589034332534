import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom/client';
import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as StateProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { store } from './state/store';
import { ThemeProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StateProvider store={store}>
    <CssBaseline />
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  </StateProvider>
);
