import React from 'react';
import { Grid } from '@mui/material';

const NotFound = () => {
  return (
    <Grid container>
      <Grid item>
        <h3>Sorry, page not found!</h3>
      </Grid>
    </Grid>
  );
};

export default NotFound;
